import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import SiteMetadata from '../components/SiteMetadata'
import Layout from '../layouts/Layout'
import { theme } from '../styles/theme'

const ProjectItem = ( props ) => {
    const {
        description,
        images,
        title,
        thumbnail,
        label,
        extraInfo,
    } = props.data.item

    const stylez = css`
      width: 80%;
      margin: 0 auto;
      ${ theme.breakpoints.mobile } {
            width: 100%;
        }
        ${ theme.breakpoints.tablet } {
            width: 100%;
        }
        ${ theme.breakpoints.largeDesktop } {
            width: 100%;
            }

      .project_item_text {
        margin-bottom: 4rem;
      }
        h1 {
          font-weight: 300 !important;
          font-size: 1.5rem;
        }
        .label_wrapper {
          margin-top: 1rem;
          font-size: ${ theme.fontSizes.small };
        }
        .project_description {
          margin-top: 2rem;
          font-weight: 100 !important;
          font-size: 1.2rem;
          ${ theme.breakpoints.mobile } {
            width: 95%;
            font-size: 1.2rem;
            }
        }

        ${ theme.breakpoints.mobile } {
          .project_item_text {
          margin-bottom: 2rem;
          }
          }

        ${ theme.breakpoints.tablet } {
          .project_item_text {
          margin-bottom: 2rem;
          }
          h1 {
            font-size: 1.5rem;
          }
          .project_description {
            font-size: ${ theme.fontSizes.medium };
          }
        }

        ${ theme.breakpoints.largeDesktop } {
              h1 {
              font-size: ${ theme.fontSizes.large };
              }
              .project_description {
              font-size: 1.5rem;
              }
            }

            @media only screen and (min-width: 470px) and (max-width: 870px) and (orientation: landscape) {
                    .label_wrapper {
                      font-size: 0.8rem;
                    }
                  }



      .project_images {
        .image_container {
          margin-bottom: 4rem;
          ${ theme.breakpoints.mobile } {
            margin-bottom: 1rem;
          }
          ${ theme.breakpoints.tablet } {
            margin-bottom: 2rem;
          }
        }
        .project_image {
          user-select: none;
          -webkit-user-select:none;
          -webkit-touch-callout:none;
        }
        .vertical {
          max-height: 80vh;
        }
        ${ theme.breakpoints.tablet } {
          .vertical {
          max-height: 100vh;
        }
       }
        .image_caption {
          font-weight: 100;
          margin-top: 0.5rem;
          text-align: center;
        }
      }

    `

    return (
        <Layout>

            <SiteMetadata
              title={ title }
              description={ description }
              image={ thumbnail.publicURL }
            />

            <div
              className='project_item_wrapper'
              css={ stylez }
            >
                <div className='project_item_text'>

                    <h1 className=''>
                        {title}
                    </h1>

                    {description && (
                    <div className='project_description'>
                        {description.description}
                    </div>
                    )}

                    {
                        label &&
                        <div className='label_wrapper'>

                            { label.map( ( labelItem, index ) =>
                                <div
                                  className='label_item'
                                  key={ index }
                                >
                                    { labelItem }
                                </div> ) }

                        </div>
                    }

                </div>

                <div className='project_images'>
                    {
                        images && images.map( image =>
                            <div
                              className='image_container'
                              key={ image.id }
                              // to avoid right clicking or mobile press to save?
                              onContextMenu={ () => false }
                            >
                                <Img
                                  className={ `project_image ${ image.localFile && ( image.localFile.childImageSharp.fluid.aspectRatio < 1 ) && 'vertical' }` }
                                  fluid={ image && image.localFile.childImageSharp.fluid }
                                  alt={ title }
                                />
                                <div className='image_caption'>
                                    { image.title }
                                </div>
                            </div> )
                    }
                </div>

                { extraInfo &&
                <div className='project_description extra_info'>
                    { extraInfo.extraInfo}
                </div>}

            </div>

        </Layout>
    )
}

export default ProjectItem

export const query = graphql`
  query ProjectItemQUery($slug: String!) {
    item: contentfulProject(slug: { eq: $slug }) {
      description {
        description
      }
      images {
        id
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      title
      thumbnail {
        localFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      slug
      label
      extraInfo {
        extraInfo
      }
    }
  }
`
